import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import store from "./store/store";
import { Provider } from "react-redux";
import StripeV1 from "./components/StripeV1";
import StripeV2 from "./components/StripeV2";
import StripeV3 from "./components/StripeV3";
import StripeV4 from "./components/StripeV4";
import StripeV5 from "./components/StripeV5";
import LayoutTheme from "./layout/index";
import Contact from "./components/InnerPages/Contact/Contact";
import QRPurchase from "./components/AddressForm/Purchase";
import Error from "./components/InnerPages/Error/Error";
import Stripe from "./components/Stripe/Stripe";
import AffiilateProgram from "./components/AffiliateProgram/AffiliateProgram";
import EZStore from "./components/EZStore/EZStore";
import EZProductPage from "./components/EZStore/EZProductPage";
import AffilateSubscription from "./components/AffilateSubscription/AffilateSubscription";
import QRService from "./components/QrService/QRService";
import Faq from "./components/FAQ/Faq";
import DashboardReview from "./components/Dashboard/DashboardReview";
import DashboardDownstream from "./components/Dashboard/DashboardDownstream";
import DashboardPaymentHistory from "./components/Dashboard/DashboardPaymentHistory";
import DashboardContact from "./components/Dashboard/DashboardContact";
import Payment from "./components/Dashboard/Payment";
import AffiliateCalculator from "./components/Dashboard/Calculator";
import GettingStarted from "./components/Dashboard/GettingStarted";
// import DaynamicQR from "./components/DynamicQR/dynamicQR";
import Ldqrpage from "./components/DynamicQR/ldqrpage";
import LandingPagePreview from "./components/DynamicQR/contactPage";
import CouponDqmb from "./components/Coupon/Coupon";
import QRLabel from "./components/AddressForm/QRLabel";
import ReorderQR from "./components/Dashboard/ReorderQR";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  // <div onContextMenu={handleRightClick}>
  <div>
    <Provider store={store}>
      <Router>
        <Routes>
          <Route exact path="/" element={<LayoutTheme />} />

          <Route path="/affiliate_link/:id" element={<LayoutTheme />} />

          <Route path="/dashboard" element={<DashboardReview />} />

          <Route
            path="/mycontact_page/:session_id?"
            element={<LandingPagePreview />}
          />

          <Route path="/dqr/:session_id?" element={<LandingPagePreview />} />

          <Route path="/qr_label/:session_id?" element={<QRLabel />} />

          <Route path="/calculator" element={<AffiliateCalculator />} />

          <Route path="/getting_started" element={<GettingStarted />} />

          <Route path="/downstream" element={<DashboardDownstream />} />

          <Route path="/payment" element={<Payment />} />

          <Route path="/reorder" element={<ReorderQR />} />

          <Route
            path="/payment_history"
            element={<DashboardPaymentHistory />}
          />

          <Route path="/contact_info" element={<DashboardContact />} />

          <Route path="/purchase" element={<QRPurchase />} />

          <Route path="/stripe" element={<Stripe />} />

          <Route path="/stripe_v1" element={<StripeV1 />} />

          <Route path="/stripe_v2" element={<StripeV2 />} />

          <Route path="/stripe_v3" element={<StripeV3 />} />

          <Route path="/stripe_v4" element={<StripeV4 />} />

          <Route path="/stripe_v5" element={<StripeV5 />} />

          <Route path="/admin_dashboard" element={<AdminDashboard />} />

          <Route path="/contact" element={<Contact />} />

          <Route path="/faq" element={<Faq />} />

          <Route path="/affiliate_program" element={<AffiilateProgram />} />

          {/* <Route path="/ezstore/:product_id?" element={<EZProductPage />} /> */}

          <Route path="/ezstore/:store_id?/:product_id?" element={<EZProductPage />} />

          <Route path="/subscription" element={<AffilateSubscription />} />

          <Route path="/qrservice" element={<QRService />} />

          <Route path="/dynamic/coupons/dqmb" element={<CouponDqmb />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </Provider>
  </div>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
