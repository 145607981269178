import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  TextField,
  Grid,
  Box,
  Container,
  Paper,
  Button,
  Pagination,
  CardContent,
  Popover,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TablePagination,
} from "@mui/material";
import axiosApiInstance from "../../store/axiosApi";
import LoadingDialog from "../Loading";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CancelIcon from "@mui/icons-material/Cancel";
import BasicModal from "../BasicModal/affiliate_modal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function FullWidthTabs() {
  const [loadingTitle, setLoadingTitle] = useState("processing...");
  const [isLoading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [payments, setPayments] = useState();
  const [currentPaymentPage, setCurrentPaymentPage] = useState(1);
  const [totalPaymentPages, setTotalPaymentPages] = useState(0);
  const [totalPaymentCounts, setTotalPaymentCounts] = useState(0);

  const [cost1, setCost1] = useState(0);
  const [cost2, setCost2] = useState(0);
  const [cost3, setCost3] = useState(0);
  const [cost4, setCost4] = useState(0);
  const [cost5, setCost5] = useState(0);
  const [podAddress, setPodAddress] = useState("");
  const [costQR, setCostQR] = useState(0);
  const [costSheet, setCostSheet] = useState(0);
  const [selectedPopId, setSelectedPopId] = useState(null);
  const [popOpen, setPopOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeTab, setActiveTab] = useState("dashboard");

  const [selectedDay, setSelectedDay] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [monthUsers, setMonthUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const [monthUsersPage, setMonthUsersPage] = useState(1);
  const [totalMonthUsersPages, setTotalMonthUsersPages] = useState(0);
  const [totalSales, setTotalSales] = useState(0);

  const [specialusers, setSpecialUsers] = useState([]);

  const [newEntry, setNewEntry] = useState({
    no: "",
    email: "",
  });

  const [products, setProducts] = useState([]);

  const [editIndex, setEditIndex] = useState(-1);

  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  const handleNewEntryChange = (e, field) => {
    setNewEntry({ ...newEntry, [field]: e.target.value });
  };

  const handleAdd = async () => {
    if (newEntry.name && newEntry.email) {
      try {
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        const token = queryParams.get("token");
        const response = await axiosApiInstance.post(
          "/save_no_subscription_user",
          {
            user: { id: 1000000, name: newEntry.name, email: newEntry.email },
            token,
          }
        );
        if (response.data.success) {
          setLoading(false);
        } else {
          setLoading(false);
        }
        window.location.reload();
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      alert("Please fill out all fields.");
    }
  };

  const handleEdit = (item) => {
    setEditIndex(item.id);
  };

  const handleSave = async (item) => {
    setEditIndex(null);
    setLoading(true);
    try {
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const token = queryParams.get("token");
      const response = await axiosApiInstance.post(
        "/save_no_subscription_user",
        {
          user: item,
          token,
        }
      );
      if (response.data.success) {
        setLoading(false);
      } else {
        setLoading(false);
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = async (item) => {
    try {
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const token = queryParams.get("token");
      const response = await axiosApiInstance.post(
        "/delete_no_subscription_user",
        {
          user: item,
          token,
        }
      );
      if (response.data.success) {
        setLoading(false);
      } else {
        setLoading(false);
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleChange = (e, item, field) => {
    const updatedUsers = specialusers.map((user) => {
      if (user.id == item.id) {
        user[field] = e.target.value;
      }
      return user;
    });
    setSpecialUsers(updatedUsers);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    fetchConstants();
  }, []);

  useEffect(() => {
    fetchPayments();
  }, []);

  useEffect(() => {
    fetchEZStore();
  }, []);

  useEffect(() => {
    if (searchValue == "") handleSearch();
  }, [searchValue]);

  useEffect(() => {
    if (selectedDay != null) fetchUsers();
  }, [monthUsersPage, selectedDay, searchValue]);

  const fetchPayments = async () => {
    setLoading(true);
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const token = queryParams.get("token");
    const email = queryParams.get("email");

    try {
      const response = await axiosApiInstance.post("/admin_payments", {
        token: token,
        email: email,
        page: currentPage,
      });
      if (response.data.success) {
        setLoading(false);
        setPayments(response.data.payments);
        setCurrentPaymentPage(response.data.currentPage);
        setTotalPaymentPages(response.data.total_pages);
        setTotalPaymentCounts(response.data.total_counts);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const showModal = (title, content) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  const showAffiliates = async (name, userId) => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const token = queryParams.get("token");
      const response = await axiosApiInstance.post("/get_admin_affiliates", {
        token: token,
        userId,
      });
      console.log(response);
      if (response.data) {
        showModal(
          `Affiliate Details of ${name}`,
          `<br/> Level 1: ${response.data.level1Month} <br/> Level 2: ${response.data.level2Month} <br/> 
          Level 4: ${response.data.level3Month} <br/> Level 4: ${response.data.level4Month} <br/> Level 5: ${response.data.level5Month} <br/>`
        );
      } else {
        showModal("Affiliate Details", "No details available for this user.");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching crypto orders:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const token = queryParams.get("token");
      const response = await axiosApiInstance.post("/admin_sales", {
        token: token,
        currentMonth,
        selectedDay,
        searchValue,
        page: monthUsersPage,
        pageSize: rowsPerPage,
      });
      console.log(response);
      setMonthUsers(response.data.monthUsers);
      setTotalMonthUsersPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching crypto orders:", error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const token = queryParams.get("token");
    const email = queryParams.get("email");

    try {
      const response = await axiosApiInstance.post("/admin_dashboard", {
        token: token,
        email: email,
        page: currentPage,
        currentMonth,
      });
      if (response.data) {
        setLoading(false);
        setEvents(response.data.monthResult);
        const total = response.data.monthResult.reduce(
          (sum, month) => sum + parseInt(month.title),
          0
        );
        setTotalSales(total);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchConstants = async () => {
    setLoading(true);
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const token = queryParams.get("token");
    const email = queryParams.get("email");

    try {
      const response = await axiosApiInstance.post("/admin_constants", {
        token: token,
      });
      if (response.data.success) {
        setLoading(false);
        console.log(response.data);
        setCost1(response.data.constants.cost1);
        setCost2(response.data.constants.cost2);
        setCost3(response.data.constants.cost3);
        setCost4(response.data.constants.cost4);
        setCost5(response.data.constants.cost5);
        setPodAddress(response.data.constants.podAddress);
        setCostQR(response.data.constants.costQr);
        setCostSheet(response.data.constants.costSheet);
        setSpecialUsers(response.data.nosubscription_emails);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchEZStore = async () => {
    setLoading(true);
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const token = queryParams.get("token");

    try {
      const response = await axiosApiInstance.post("/get_admin_products", {
        token: token,
      });
      if (response.data.success) {
        setLoading(false);
        console.log(555, response.data);
        setProducts(response.data.products);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const showStatusModal = (str) => {
    setStatusModalShow(true);
    setStatusModalText(str);
  };

  const handleChangeConstant = async (type) => {
    setLoading(true);
    setConfirmModalShow(false);
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const token = queryParams.get("token");
    const email = queryParams.get("email");

    const response = await axiosApiInstance.post("/set_admin_constants", {
      saveType: type,
      token,
      podAddress,
      costQR,
      costSheet,
      cost1,
      cost2,
      cost3,
      cost4,
      cost5,
    });
    if (response.data.success) {
      setLoading(false);
      console.log(response.data);
      showStatusModal("Data changed successfully.");
    } else {
      showStatusModal("Something went wrong.");
      setLoading(false);
    }
  };

  const handleType = (str) => {
    setSaveType(str);
    handleChangeConstant(str);
  };
  const handleDateClick = (arg) => {
    setSelectedDay(arg.dateStr);
  };

  const handleDatesSet = (dateInfo) => {
    console.log("Datesset is called");
    const startDate = new Date(dateInfo.start);
    const endDate = new Date(dateInfo.end);
    const midDate = new Date((startDate.getTime() + endDate.getTime()) / 2);
    setCurrentMonth(midDate);
    setSelectedDay(null);
  };

  const handleEventClick = (arg) => {
    console.log(arg.event.startStr);
    setSelectedDay(arg.event.startStr);
  };

  const handleSearch = () => {
    if (selectedDay != null) fetchUsers();
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <TransitionGroup>
          <CSSTransition
            key={eventInfo.event.date}
            classNames="fade"
            timeout={{ enter: 300, exit: 300 }}
          >
            <div className="event-content">{eventInfo.event.title}</div>
          </CSSTransition>
        </TransitionGroup>
      </>
    );
  };

  const displayNameInitials = (fullName) => {
    if (fullName == null) return "";
    const nameParts = fullName.trim().split(" ");
    // If there is only one part, return it as is
    if (nameParts.length === 1) {
      return fullName;
    }
    const firstName = nameParts[0];
    const restInitials = nameParts
      .slice(1)
      .map((part) => part.charAt(0) + ".")
      .join(" ");

    return `${firstName} ${restInitials}`;
  };

  const handleTabChange = (step) => {
    setActiveTab(step);
  };

  return (
    <React.Fragment>
      {isLoading ? <LoadingDialog title={loadingTitle} /> : <div />}
      <Container component="main" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 3 } }}
        >
          <React.Fragment>
            <Box sx={{ bgcolor: "background.paper" }}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    onClick={() => handleTabChange("dashboard")}
                    color="primary"
                    className="btn-submit"
                  >
                    Dashboard
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    color="success"
                    className="btn-submit"
                    onClick={() => handleTabChange("constants")}
                    sx={{ ml: 2 }}
                  >
                    Constants
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-submit"
                    onClick={() => handleTabChange("payment")}
                    sx={{ ml: 2 }}
                  >
                    Payments
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    className="btn-submit"
                    onClick={() => handleTabChange("ezstore")}
                    sx={{
                      backgroundColor: "#834bff",
                      '&:hover': {
                        backgroundColor: "#651fff" // Optionally set hover color.
                      },
                      ml: 2 }}
                  >
                    EZStore
                  </Button>
                </Grid>
              </Grid>

              {activeTab === "dashboard" && (
                <div id="home" className="container tab-pane active">
                  <Container
                    component="main"
                    sx={{ mb: 4 }}
                    className="mainContainer"
                  >
                    <Paper
                      variant="outlined"
                      sx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 3 } }}
                    >
                      <React.Fragment>
                        <Box sx={{ bgcolor: "background.paper" }}>
                          <h3
                            style={{
                              textAlign: "center",
                              marginBottom: "55px",
                            }}
                          >
                            The QR Store Acivity Overview
                          </h3>
                          <Grid container style={{ height: "80vh" }}>
                            <Grid item xs={12} md={6}>
                              <div className="level-select admin-dashboard-downstream">
                                <div className="levels-summary">
                                  <span>
                                    <b>Total = {totalSales}</b>
                                  </span>
                                </div>
                              </div>
                              <FullCalendar
                                plugins={[dayGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                events={events}
                                dateClick={handleDateClick}
                                datesSet={handleDatesSet}
                                eventClick={handleEventClick}
                                editable={true}
                                selectable={true}
                                eventContent={renderEventContent}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className="downstream-users-grid"
                            >
                              <p>
                                <b>
                                  {selectedDay
                                    ? `Details for ${selectedDay}`
                                    : null}
                                </b>
                              </p>
                              <div className="downstream-users">
                                <TextField
                                  fullWidth
                                  label="Search name."
                                  type="text"
                                  value={searchValue}
                                  onChange={(e) => {
                                    setSearchValue(e.target.value);
                                  }}
                                  onKeyUp={(e) => {
                                    if (e.key === "Enter") handleSearch();
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <IconButton
                                        onClick={() => {
                                          setSearchValue("");
                                        }}
                                        style={{
                                          marginRight: "-10px",
                                          padding: "0px",
                                        }}
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    ),
                                  }}
                                  style={{ width: "225px" }}
                                />{" "}
                                {monthUsers.length > 0 ? (
                                  <Pagination
                                    count={totalMonthUsersPages}
                                    page={monthUsersPage}
                                    onChange={(event, newPage) =>
                                      setMonthUsersPage(newPage)
                                    }
                                    color="primary"
                                    siblingCount={1}
                                    boundaryCount={1}
                                    style={{ marginLeft: "50px" }}
                                  />
                                ) : null}
                              </div>

                              <TableContainer
                                style={{ height: "500px", overflow: "auto" }}
                              >
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>No</TableCell>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Purchased</TableCell>
                                      <TableCell>Activity</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {monthUsers.map((monthUser, index) => {
                                      return (
                                        <TableRow hover key={index}>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>
                                            {displayNameInitials(
                                              monthUser.full_name
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {monthUser.date}
                                          </TableCell>
                                          <TableCell className="activity-count">
                                            {monthUser.count_affiliate > 0 ? (
                                              <span
                                                onClick={() =>
                                                  showAffiliates(
                                                    displayNameInitials(
                                                      monthUser.full_name
                                                    ),
                                                    monthUser.id
                                                  )
                                                }
                                              >
                                                {monthUser.count_affiliate}
                                              </span>
                                            ) : (
                                              0
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Box>
                      </React.Fragment>
                      <Popover
                        id={selectedPopId}
                        open={popOpen}
                        anchorEl={anchorEl}
                        onClose={() => {
                          setPopOpen(false);
                          setAnchorEl(null);
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <CardContent className="userPopoverCard">
                          <Typography>
                            Full Name: {selectedUser?.full_name}
                          </Typography>
                          <hr />
                          <Typography>
                            Address: {selectedUser?.street},{selectedUser?.city}
                            , {selectedUser?.state} {selectedUser?.zipcode},{" "}
                            {selectedUser?.country}
                          </Typography>
                          <hr />
                          <Typography>Email: {selectedUser?.email}</Typography>
                          <hr />
                          <Typography>
                            Phone: {selectedUser?.phone_mobile}{" "}
                            {selectedUser?.phone_work}
                          </Typography>
                        </CardContent>
                      </Popover>
                      <BasicModal
                        open={modalOpen}
                        title={modalTitle}
                        content={modalContent}
                        showButton={false}
                        handleClose={() => {
                          setModalOpen(false);
                        }}
                        handleClick={() => {
                          setModalOpen(false);
                        }}
                      />
                    </Paper>
                  </Container>
                </div>
              )}
              {activeTab === "constants" && (
                <div id="menu1">
                  <br />
                  <div>
                    <div>
                      <div>
                        <h3 style={{ marginBottom: "25px", marginTop: "25px" }}>
                          Global Constants of QR store.
                        </h3>
                        <Grid full-container>
                          <Grid item xs={12} sm={10} md={8}>
                            <h3 style={{ textAlign: "center" }}>
                              {" "}
                              No Subscription Users{" "}
                            </h3>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Actions</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {specialusers
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((item, index) => (
                                      <TableRow key={item.id}>
                                        <TableCell>
                                          {rowsPerPage * page + index + 1}
                                        </TableCell>
                                        <TableCell>
                                          {editIndex === item.id ? (
                                            <TextField
                                              value={item.name}
                                              onChange={(e) =>
                                                handleChange(e, item, "name")
                                              }
                                            />
                                          ) : (
                                            item.name
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {editIndex === item.id ? (
                                            <TextField
                                              value={item.email}
                                              onChange={(e) =>
                                                handleChange(e, item, "email")
                                              }
                                            />
                                          ) : (
                                            item.email
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {editIndex === item.id ? (
                                            <Button
                                              onClick={() => handleSave(item)}
                                            >
                                              Save
                                            </Button>
                                          ) : (
                                            <Button
                                              onClick={() => handleEdit(item)}
                                            >
                                              Edit
                                            </Button>
                                          )}
                                          <Button
                                            onClick={() => handleDelete(item)}
                                          >
                                            Delete
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  <TableRow>
                                    <TableCell>-</TableCell>
                                    <TableCell>
                                      <TextField
                                        value={newEntry.name}
                                        onChange={(e) =>
                                          handleNewEntryChange(e, "name")
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        value={newEntry.email}
                                        onChange={(e) =>
                                          handleNewEntryChange(e, "email")
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Button onClick={handleAdd}>Add</Button>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                              <TablePagination
                                rowsPerPageOptions={[5]}
                                component="div"
                                count={specialusers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                              />
                            </TableContainer>
                          </Grid>
                        </Grid>
                        <div className="form-group row">
                          <label className="col-lg-4 col-sm-4">
                            POD email address
                          </label>
                          <div className="col-lg-5 col-sm-5">
                            <input
                              type="text"
                              className="form-control"
                              value={podAddress}
                              onChange={(e) => {
                                setPodAddress(e.target.value);
                              }}
                              placeholder="Email"
                            />
                          </div>
                          <div className="col-lg-2 col-sm-2 text-center">
                            <Button
                              className="btn-checkout"
                              onClick={() => {
                                handleType("podAddress");
                              }}
                              variant="contained"
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-4 col-lg-4 col-md-4 col-form-label">
                            Payment Amount Level1
                          </label>
                          <div className="col-sm-5 col-lg-5 col-md-5">
                            <input
                              type="text"
                              className="form-control"
                              value={cost1}
                              onChange={(e) => {
                                setCost1(e.target.value);
                              }}
                              placeholder="Email"
                            />
                          </div>
                          <div className="col-sm-2 text-center">
                            <Button
                              className="btn-checkout"
                              variant="contained"
                              onClick={() => {
                                handleType("cost1");
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-4 col-lg-4 col-md-4 col-form-label">
                            Payment Amount Level2
                          </label>
                          <div className="col-sm-5 col-lg-5 col-md-5">
                            <input
                              type="text"
                              className="form-control"
                              value={cost2}
                              onChange={(e) => {
                                setCost2(e.target.value);
                              }}
                              placeholder="Email"
                            />
                          </div>
                          <div className="col-sm-2 text-center">
                            <Button
                              className="btn-checkout"
                              variant="contained"
                              onClick={() => {
                                handleType("cost2");
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-4 col-lg-4 col-md-4 col-form-label">
                            Payment Amount Level3
                          </label>
                          <div className="col-sm-5 col-lg-5 col-md-5">
                            <input
                              type="text"
                              className="form-control"
                              value={cost3}
                              onChange={(e) => {
                                setCost3(e.target.value);
                              }}
                              placeholder="Email"
                            />
                          </div>
                          <div className="col-sm-2 text-center">
                            <Button
                              className="btn-checkout"
                              variant="contained"
                              onClick={() => {
                                handleType("cost3");
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-4 col-lg-4 col-md-4 col-form-label">
                            Payment Amount Level4
                          </label>
                          <div className="col-sm-5 col-lg-5 col-md-5">
                            <input
                              type="text"
                              className="form-control"
                              value={cost4}
                              onChange={(e) => {
                                setCost4(e.target.value);
                              }}
                              placeholder="Email"
                            />
                          </div>
                          <div className="col-sm-2 text-center">
                            <Button
                              className="btn-checkout"
                              variant="contained"
                              onClick={() => {
                                handleType("cost4");
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-4 col-lg-4 col-md-4 col-form-label">
                            Payment Amount Level5
                          </label>
                          <div className="col-sm-5 col-lg-5 col-md-5">
                            <input
                              type="text"
                              className="form-control"
                              value={cost5}
                              onChange={(e) => {
                                setCost5(e.target.value);
                              }}
                              placeholder="Email"
                            />
                          </div>
                          <div className="col-sm-2 text-center">
                            <Button
                              className="btn-checkout"
                              variant="contained"
                              onClick={() => {
                                handleType("cost5");
                              }}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "payment" && (
                <div id="home" className="container tab-pane active">
                  <br />
                  <div>
                    <h2 style={{ textAlign: "center" }}>Affiliate Payments</h2>
                    {payments.length > 0 ? (
                      <div>
                        <table className="table" style={{ marginTop: "25px" }}>
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name(Email)</th>
                              <th scope="col">Withdraw Date</th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Amount
                              </th>
                              <th>Method</th>
                            </tr>
                          </thead>

                          {payments.length > 0 ? (
                            <tbody>
                              {payments.map((item, index) => (
                                <tr key={index}>
                                  <td>{(currentPage - 1) * 20 + index + 1}</td>
                                  <td>
                                    {item.full_name}({item.email})
                                  </td>
                                  <td>
                                    {item.created != null
                                      ? item.created.substring(0, 10)
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.amount}
                                  </td>
                                  <td>
                                    {item.fund_method == 0 ? "PayPal" : "Venmo"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <div>
                              <p style={{ textAlign: "center" }}>
                                No payments.
                              </p>
                            </div>
                          )}
                        </table>
                      </div>
                    ) : (
                      <div>
                        <table className="table" style={{ marginTop: "25px" }}>
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name(Email)</th>
                              <th scope="col">Withdraw Date</th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Amount
                              </th>
                              <th>Method</th>
                            </tr>
                          </thead>
                          <tbody />
                        </table>
                        {isLoading ? (
                          <p style={{ textAlign: "center" }}>loading...</p>
                        ) : (
                          <p style={{ textAlign: "center" }}>No payments.</p>
                        )}
                      </div>
                    )}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {totalPaymentPages != 0 ? (
                        <Pagination
                          onChange={(event, page) => {
                            if (currentPaymentPage != page) {
                              setCurrentPaymentPage(page);
                            }
                          }}
                          count={totalPaymentPages}
                          color="primary"
                        />
                      ) : (
                        <div />
                      )}
                    </Box>
                  </div>
                </div>
              )}
              {activeTab === "ezstore" && (
                <div id="home" className="container tab-pane active">
                  <Container
                    component="main"
                    sx={{ mb: 4 }}
                    className="mainContainer"
                  >
                    <Paper
                      variant="outlined"
                      sx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 3 } }}
                    >
                      <React.Fragment>
                        <Box sx={{ bgcolor: "background.paper" }}>
                          <h3
                            style={{
                              textAlign: "center",
                              marginBottom: "55px",
                            }}
                          >
                           EZStore Acivity Overview
                          </h3>
                          <Grid container style={{ height: "80vh" }}>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className="downstream-users-grid"
                            >
                              <TableContainer
                                style={{ height: "500px", overflow: "auto" }}
                              >
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>No</TableCell>
                                      <TableCell>Product</TableCell>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Store</TableCell>
                                      <TableCell>Total Visits</TableCell>
                                      <TableCell>Total Purchases</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {products.map((product, index) => {
                                      return (
                                        <TableRow hover key={index}>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>
                                            <img src={product.image_url} alt={product.product_name} className="admin--product-image" />
                                          </TableCell>
                                          <TableCell>
                                            {product.product_name}
                                          </TableCell>
                                          <TableCell>
                                            {product.store_name}
                                          </TableCell>
                                          <TableCell>
                                            {product.visits}
                                          </TableCell>
                                          <TableCell>
                                            {product.purchases}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Box>
                      </React.Fragment>
                      <BasicModal
                        open={modalOpen}
                        title={modalTitle}
                        content={modalContent}
                        showButton={false}
                        handleClose={() => {
                          setModalOpen(false);
                        }}
                        handleClick={() => {
                          setModalOpen(false);
                        }}
                      />
                    </Paper>
                  </Container>
                </div>
              )}

              <Popover
                id={selectedPopId}
                open={popOpen}
                anchorEl={anchorEl}
                onClose={() => {
                  setPopOpen(false);
                  setAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <CardContent>
                  <Typography>Name:{selectedUser?.full_name}</Typography>
                  <Typography>
                    Address:{selectedUser?.street},{selectedUser?.city},{" "}
                    {selectedUser?.state} {selectedUser?.zipcode},{" "}
                    {selectedUser?.country}
                  </Typography>
                  <Typography>Email: {selectedUser?.email}</Typography>
                  <Typography>
                    Phone: {selectedUser?.phone_mobile}{" "}
                    {selectedUser?.phone_work}
                  </Typography>
                </CardContent>
                <BasicModal
                  open={modalOpen}
                  title={modalTitle}
                  content={modalContent}
                  showButton={false}
                  handleClose={() => {
                    setModalOpen(false);
                  }}
                  handleClick={() => {
                    setModalOpen(false);
                  }}
                />
              </Popover>
            </Box>
          </React.Fragment>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
